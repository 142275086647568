body {
  margin: 0;
  padding: 0;
}
.App {
  display: flex;
  height: 100vh;
}

/* Sidebar */
.pro-sidebar {
  width: 250px;
  transition: width 0.2s;
  flex-shrink: 0;
  overflow-y: auto;
  z-index: 1;
  background-color: #000000;
}

/* Collapsed Sidebar */
.App.collapsed .pro-sidebar {
  width: 80px;
}

/* Main Content */
main {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #D5E3EF;
  box-sizing: border-box;
}

h1 {
     color: #165a72;
     margin: 50px auto;
     font-size: 40px;
}
.menu1 {
     margin-bottom: 40px;
     margin-top: 20px;
}
h2 {
     color: #165a72;
}
