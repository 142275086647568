.bank-account-container {
  width: 100%;
}

.bank-account {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
  min-width: 0;
  margin: 20px;
}

@media (min-width: 768px) {
  .bank-account {
    flex-basis: 35%;
    width: 100%;
  }
}
