/* Add any custom styles for the dashboard here */
.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}

.row {
  display: flex;
  gap: 20px;
}

.top-bar {
  padding: 10px;
  height: 30px;
  background-color: #f0f0f0;
  border-color: #000000;
  border-width: 0.25px;
  border-style: solid;
  flex: 1;
}
.box {
  padding: 10px;
  height: 100px;
  border-radius: 10px;
  background-color: #ffffff;
  border-color: #000000;
  border-width: 0.5px;
  border-style: solid;
  flex: 1;
}

.bigger-box {
  flex: 2;
  border-radius: 10px;
  border-color: #000000;
  border-width: 0.5px;
  border-style: solid;
  background-color: #ffffff;
  padding: 20px;
  width: auto;
}

.chart-box {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  border-color: #000000;
  border-width: 0.5px;
  border-style: solid;
  background-color: #ffffff;
}

.arr-mrr-values {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Change the color to your preference */
  margin-top: 5px; /* Add some spacing to separate it from the heading */
}

.value-container {
  margin-top: 10px; /* Add some spacing between the heading and the revenue value */
}

.datepicker {
  width: 100%;
}

.datepicker-container {
  float: right;
  margin: 20px;
}

main {
  width: 100%;
}
